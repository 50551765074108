import React from 'react'
import { ContactContainer, Form, Label, Input, Textarea, Button, Star } from './Contact.elements'
const Contact = () => {
    return (
        <ContactContainer>
            <Form action="https://formsubmit.co/sujithbellam@gmail.com" method="POST">
                <Label for="customerName">NAME <Star>&#x2a;</Star></Label>
                <Input id="customerName" name="customerName" required="" type="text" />
                <Label for="customerEmail">EMAIL <Star>&#x2a;</Star></Label>
                <Input id="customerEmail" name="email" required="" type="email" />
                <Label for="customerNote">YOUR MESSAGE <Star>&#x2a;</Star></Label>
                <Textarea id="customerNote" name="customerNote" required="" rows="4"></Textarea>
                <Button id="customerOrder">SUBMIT</Button>
            </Form>
        </ContactContainer>
    )
}

export default Contact
