import styled from "styled-components"
import bg1 from '../../../media/profile-1.svg'
import bg2 from '../../../media/profile-2.svg'
import bg3 from '../../../media/profile-3.svg'
import bg4 from '../../../media/profile-4.svg'

export const Container1 = styled.div`
    color: #fff;
    background: url(${bg1});
    background-size: auto;
    background-repeat: no-repeat;
    min-height: 90vh;
    height: auto;
`
export const ProfileContainer = styled.div`
    padding: 5%;
    text-align: center;
`
export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    
    @media screen and (max-width: 960px) {
        
    }
`
export const Para = styled.p`
    margin: 30px 150px;
    text-align: justify;
    font-size: 20px;

    @media screen and (max-width: 960px) {
        margin: 20px 40px;
    }
`
export const DownloadContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
`
export const DownloadInfo = styled.div`
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 220px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
    text-align: center;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        justify-content: center;
        padding: 5%;
    }
`
export const DownloadImg = styled.div`
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 20px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`
export const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
`
export const Img = styled.img`
    padding-right: 0;
    border: 0;
    max-width: 60%;
    vertical-align: middle;
    display: inline-block;
`
export const Button = styled.button`
    display: block;
    float: left;
    line-height: 24pt;
    margin-top: 30px;
    padding: 5px 20px;
    border: none;
    background: #FF007C;
    color: white;
    letter-spacing: 2px;
    transition: 0.2s all ease-in-out;
    border-bottom: 2px solid transparent;
    outline: none;
    cursor: pointer;
    :hover {
        background: inherit;
        color: #01C6FF;
        border-bottom: 2px solid #01C6FF;
    }
    
    @media screen and (max-width: 960px) {
        margin-left: 35%;
    }
`
export const Container2 = styled.div`
    color: #fff;
    min-height: 100vh;
    height: auto;
    background: url(${bg2});
    background-size: auto;
    background-repeat: repeat;
    padding-bottom: 3%;
`
export const Projects = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    @media (max-width: 768px) {
        justify-content: center;
    }
`
export const Project = styled.div`
    flex: 1 0 30rem;
    height: auto;
    border: 1px dotted white;
    @media (max-width: 960px) {
        flex: 1 0 20rem;
  }
`
export const ProjectPosition = styled.div`
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(1,1,1, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`
export const ProjectImg = styled.img`
    width: 100%;
    cursor: pointer;
`
export const ProjectDetail = styled.div`
    font-size: 1.6rem;
    padding-top: 0.8rem;
    text-align: left;
`
export const ProjectDesc = styled.p`
    margin: 0;
`
export const Container3 = styled.div`
    color: #fff;
    padding: 5%;
    text-align: center;
    min-height: 100vh;
    height: auto;
    background: url(${bg3});
    background-size: 100%;
`
export const ParticipationContainer = styled.div`
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
    border-radius: 15px;
`
export const ParticipationList = styled.ul`
    list-style: none;
`
export const ParticipationListItem = styled.li`
    font-size: 1.6rem;
    padding: 2%;
    :hover {
        background: rgba(0,0,0,0.7);
    }
`
export const SkillsContainer = styled.div`
    margin-top: 5%;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid white;
    border-radius: 15px;
`
export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`
export const SkillsList = styled.ul`
    list-style: none;
    text-align: center;
    font-size: 1.3em;
`
export const SkillsListItems = styled.li`
    padding: 2%;
    border: none;
    :hover {
        background: rgba(0,0,0,0.7);
    }
`
export const Container4 = styled.div`
    text-align: center;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: url(${bg4});
    background-repeat: repeat;
    background-size: auto;
`
export const Container5 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    @media screen and (max-width: 960px) {
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
    }
`
export const Container6 = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @media screen and (max-width: 960px) {
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
    }
`
export const End = styled.div`
    padding: 5%;
`
export const EndList = styled.ul`
    list-style: none;
`
export const EndListItems = styled.li`
    padding: 5%;
    font-weight: 500;
`