import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import GlobalStyle from './gobalStyles'
import ScrollToTop from './components/ScrollToTop'
import Navigation from './components/Navigation/Navigation'
import Home from './components/Pages/Home/Home'
import Profile from './components/Pages/Profile/Profile'
import Contact from './components/Pages/Contact/Contact'
import Footer from './components/Footer/Footer'
import './App.css';

function App() {
  return (
      <Router>
        <GlobalStyle />
        <ScrollToTop />
        <Navigation/>
        <Switch>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer/>
      </Router>
  )
}

export default App;
