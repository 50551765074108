import React from 'react'
import { HomeSec, InfoRow, InfoColumn, TextWrapper, Heading, ImgWrapper, Img } from './Home.elements'
import { Container } from '../../../gobalStyles'
import homeImg from '../../../media/home.svg'
import Typical from '../../../Typical/Typical'

const Home = () => {
    return (
        <>
            <HomeSec>
                <Container>
                    <InfoRow>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading>Hello, </Heading>
                                <Heading>I'm
                                    <Typical
                                        loop={Infinity}
                                        wrapper="a"
                                        steps={[
                                            ' Sujith Bellam', 1000,
                                            ' a Cybersecurity Student', 1000
                                        ]}
                                    />
                                </Heading>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <ImgWrapper>
                                <Img src={homeImg} alt={"Home Page Image"} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </HomeSec>
        </>
    )
}

export default Home
