import styled from 'styled-components'
import backgroundImg from '../../../media/contact.svg'

export const ContactContainer = styled.div`
    min-height: 100vh;
    height: auto;
    background: url(${backgroundImg});
    padding: 50px 100px;
    border-top: 10px solid #FF007C;
    border-bottom: 10px solid #01C6FF;
`
export const Form = styled.form`
    background: transparent;
    padding: 3%;
    ::selection {
        background: #ffc7b8;
    }
`
export const Label = styled.label`
    color: #fff;
    display: block;
    width: 100%;
    font-size: 12pt;
    line-height: 24pt;
`
export const Input = styled.input`
    display: block;
    width: 100%;
    font-size: 12pt;
    line-height: 24pt;
    margin-bottom: 24pt;
    border: none;
    border: 1px solid #fff;
    border-radius: 2px;
    background: transparent;
    padding-left: 5px;
    color: white;
    outline: none;
    :focus {
        border: 1px solid #FF007C;
    }
`
export const Textarea = styled.textarea`
    display: block;
    width: 100%;
    resize: vertical;
    font-size: 12pt;
    line-height: 24pt;
    border: none;
    border: 1px solid #fff;
    border-radius: 2px;
    background: transparent;
    padding-left: 5px;
    outline: none;
    color: #fff;
    max-height: 200px;
    :focus {
        border: 1px solid #FF007C;
    }
`
export const Button = styled.button`
    display: block;
    float: right;
    line-height: 24pt;
    margin-top: 30px;
    padding: 5px 20px;
    border: none;
    background: #FF007C;
    color: white;
    letter-spacing: 2px;
    transition: 0.2s all ease-in-out;
    border-bottom: 2px solid transparent;
    outline: none;
    cursor: pointer;
    :hover {
        background: inherit;
        color: #01C6FF;
        border-bottom: 2px solid #01C6FF;
    }
`
export const Star = styled.em `
    color: #f45702;
    font-weight: bold;
`
