import React from 'react'
import {
    Container1, ProfileContainer, Heading, Para, DownloadContainer, DownloadInfo, DownloadImg, Img, ImgWrapper,
    Container2, Projects, Project, ProjectPosition, ProjectImg, ProjectDetail, ProjectDesc, Container3, ParticipationContainer,
    ParticipationList, ParticipationListItem, SkillsContainer, ListContainer, SkillsList, SkillsListItems, Container4,
    Container5, Container6, End, EndList, EndListItems
} from '../Profile/Profile.elements'
import { Button } from '../../../gobalStyles'
import resume from '../../../media/Sujith-Bellam_Resume.pdf'
import digi1 from '../../../media/digi-1.pdf'
import digi2 from '../../../media/digi-2.pdf'
import os1 from '../../../media/os-1.pdf'
import os2 from '../../../media/os-2.pdf'
import os3 from '../../../media/os-3.pdf'
import os4 from '../../../media/os-4.pdf'
import dell from '../../../media/dell.pdf'
import game from '../../../media/game.zip'
import sim from '../../../media/sim.zip'
import downloadImg from '../../../media/download.svg'
import project1 from '../../../media/project-1.png'
import project2 from '../../../media/project-2.png'
import project3 from '../../../media/project-3.png'
import project4 from '../../../media/project-4.png'
import project5 from '../../../media/project-5.png'
import project6 from '../../../media/project-6.png'
import project7 from '../../../media/project-7.png'
import project8 from '../../../media/project-8.png'
import project9 from '../../../media/project-9.png'
import project10 from '../../../media/project-10.png'
import project11 from '../../../media/project-11.png'
import project12 from '../../../media/project-12.png'

const Profile = () => {
    return (
        <>
            <Container1>
                <ProfileContainer>
                    <Heading>Profile</Heading>
                    <Para>My name is Sujith Bellam, I am currently pursuing a Bachelor of Cybersecurity at Macquarie
                        University while keeping an eye out for any exciting internships.
                        My love for computers lead me to learn about how they work and eventually and
                        developed my passion for programming. I like coding, playing video games, and learning
                        new things. I am proficient in C, Java and Python programming languages.
                    </Para>
                </ProfileContainer>
                <DownloadContainer>
                    <DownloadInfo>
                        <Heading>Download Resume</Heading>
                        <Button big fontBig type="submit" onClick={() => { window.open(resume) }}>Download</Button>
                    </DownloadInfo>
                    <DownloadImg>
                        <ImgWrapper>
                            <Img src={downloadImg} alt={"Download Image"} />
                        </ImgWrapper>
                    </DownloadImg>
                </DownloadContainer>
            </Container1>
            <Container2>
                <ProfileContainer>
                    <Heading>Projects</Heading>
                </ProfileContainer>
                <Projects>
                    <Project>
                        <ProjectPosition>
                            <a href='https://confident-noether-393b8c.netlify.app/' target='_blank' rel="noreferrer">
                                <ProjectImg src={project1} alt="Project 1" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>Camilerri Berries Website (2020)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href="https://angry-wescoff-844e85.netlify.app/" target='_black' rel="noreferrer">
                                <ProjectImg src={project2} alt="Project 2" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>FlowTow Website (2021)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href="https://individual-project-matrix.herokuapp.com/" target="_blank" rel="noreferrer">
                                <ProjectImg src={project3} alt="Project 3" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>Wings Of Words Website (2021)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={digi1} target="_blank" rel="noreferrer">
                                <ProjectImg src={project4} alt="Project 4" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>Digital Forensics Report on the Computer of the suspect caught by ASIO (Australian Security Intelligennce Organisation) (2021)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={digi2} target="_blank" rel="noreferrer">
                                <ProjectImg src={project5} alt="Project 5" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>Digital Forensicis Report on suspect's hard disk (2021)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={os1} target="_blank" rel="noreferrer">
                                <ProjectImg src={project6} alt="Project 6" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>CTF Writeup on Macquarie University CTF-1 competetion 2021</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={os2} target="_blank" rel="noreferrer">
                                <ProjectImg src={project7} alt="Project 7" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>CTF Writeup on Macquarie University CTF-2 competetion 2021</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={os3} target="_blank" rel="noreferrer">
                                <ProjectImg src={project8} alt="Project 8" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>CTF Writeup on Macquarie University CTF-3 competetion 2021</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={os4} target="_blank" rel="noreferrer">
                                <ProjectImg src={project9} alt="Project 9" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>CTF Writeup on Macquarie University CTF-4 competetion 2021</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={dell} target="_blank" rel="noreferrer">
                                <ProjectImg src={project10} alt="Project 10" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>Carbon Neutralisation Strategies report for Dell Technologies (2021)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={game} target="_blank" rel="noreferrer">
                                <ProjectImg src={project11} alt="Project 11" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>2-D Shooting Game on Java (2020)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                    <Project>
                        <ProjectPosition>
                            <a href={sim} target="_blank" rel="noreferrer">
                                <ProjectImg src={project12} alt="Project 12" />
                            </a>
                        </ProjectPosition>
                        <ProjectDetail>
                            <ProjectDesc>Covid-19 Infection, Death and Vaccination simulation on Java (2020)</ProjectDesc>
                        </ProjectDetail>
                    </Project>
                </Projects>
            </Container2>
            <Container3>
                <ParticipationContainer>
                    <Heading>Participations</Heading>
                    <ParticipationList>
                        <ParticipationListItem>MUN (Model United Nations) Events</ParticipationListItem>
                        <ParticipationListItem>Jain Robotics Club</ParticipationListItem>
                        <ParticipationListItem>Macquarie HTB (Hack The Box) and  CTF (Capture The Flag) Events</ParticipationListItem>
                        <ParticipationListItem>NeveLAN CTF Event</ParticipationListItem>
                    </ParticipationList>
                </ParticipationContainer>
                <SkillsContainer>
                    <Heading>Skills</Heading>
                    <ListContainer>
                        <SkillsList>
                            <SkillsListItems>C</SkillsListItems>
                            <SkillsListItems>Python</SkillsListItems>
                            <SkillsListItems>Java</SkillsListItems>
                            <SkillsListItems>HTML</SkillsListItems>
                            <SkillsListItems>JavaScript</SkillsListItems>
                            <SkillsListItems>CSS</SkillsListItems>
                            <SkillsListItems>React</SkillsListItems>
                            <SkillsListItems>NodeJS</SkillsListItems>
                            <SkillsListItems>Full Stack Development</SkillsListItems>
                            <SkillsListItems>Database Administration</SkillsListItems>
                        </SkillsList>
                        <SkillsList>
                            <SkillsListItems>SQL</SkillsListItems>
                            <SkillsListItems>Basic Networking</SkillsListItems>
                            <SkillsListItems>Systems Programming</SkillsListItems>
                            <SkillsListItems>Data Science</SkillsListItems>
                            <SkillsListItems>Machine Learning</SkillsListItems>
                            <SkillsListItems>Cryptography</SkillsListItems>
                            <SkillsListItems>Web Development</SkillsListItems>
                            <SkillsListItems>Digital Forensics</SkillsListItems>
                            <SkillsListItems>Offensive Security</SkillsListItems>
                            <SkillsListItems>Cybersecurity Mangement Practices</SkillsListItems>
                        </SkillsList>
                    </ListContainer>
                </SkillsContainer>
            </Container3>
            <Container4>
                <Container5>
                    <End>
                        <Heading>Hobbies</Heading>
                        <EndList>
                            <EndListItems>Coding</EndListItems>
                            <EndListItems>Gaming</EndListItems>
                            <EndListItems>CTF Events</EndListItems>
                            <EndListItems>HTB and THM challenges</EndListItems>
                            <EndListItems>Listening to Music</EndListItems>
                        </EndList>
                    </End>
                    <End>
                        <Heading>Languages</Heading>
                        <EndList>
                            <EndListItems>English - Fluent </EndListItems>
                            <EndListItems>Telugu - Fluent (Native)</EndListItems>
                            <EndListItems>Hindi - Intermmediate </EndListItems>
                            <EndListItems>French - Beginner</EndListItems>
                        </EndList>
                    </End>
                </Container5>
                <Container6>
                    <End>
                        <Heading>Experience</Heading>
                        <EndList>
                            <EndListItems><h3>Macquarie University (Tutor)</h3></EndListItems>
                            <EndListItems>Febraury 2022 - Present</EndListItems>
                            <EndListItems><h3>Dell Technologies (Internship)</h3></EndListItems>
                            <EndListItems>May 2021 - June 2021</EndListItems>
                        </EndList>
                    </End>
                    <End>
                        <Heading>Education</Heading>
                        <EndList>
                            <EndListItems><h3>Carnegie Mellon University (Master of Science in Information Security Policy and Mangement)</h3></EndListItems>
                            August 2022 - May 2024
                            <EndListItems>Pittsburgh, USA</EndListItems>
                            <EndListItems><h3>Macquarie University (Bachelor of Cybersecurity)</h3></EndListItems>
                            January 2019 - June 2022
                            <EndListItems>Sydney, Australia</EndListItems>
                            <EndListItems><h3>Jain International Residential School (International Baccalaureate)</h3></EndListItems>
                            Febraury 2016 - June 2018
                            <EndListItems>Bangalore, India</EndListItems>
                        </EndList>
                    </End>
                </Container6>
            </Container4>
        </>
    )
}

export default Profile
