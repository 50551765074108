import React, { useState } from 'react'
import { IconContext } from 'react-icons/lib'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, NavIcon, MobileIcon, NavMenu, NavItem, NavLinks } from './Navigation.elements'

const Navigation = () => {
    const [click, setClick] = useState(false)

    const clickHandler = () => setClick(!click)

    var url = window.location.href.split('/')[3]

    let home = false
    let profile = false
    let contact = false

    if(url === ''){
        home = true
        profile = false
        contact = false
    } else if (url === 'profile') {
        home = false
        profile = true
        contact = false
    } else if (url === 'contact') {
        home = false
        profile = false
        contact = true
    } else {
        home = false
        profile = false
        contact = false
    }

    return (
        <IconContext.Provider value={{color: '#fff'}}>
            <Nav>
                <NavbarContainer>
                    <NavLogo to="/">
                        <NavIcon />
                        SB
                    </NavLogo>
                    <MobileIcon onClick={clickHandler}>
                        {click ? <FaTimes /> : <FaBars />}
                    </MobileIcon>
                    <NavMenu onClick={clickHandler} click={click}>
                        <NavItem>
                            <NavLinks active={home} to="/">Home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks active={profile} to="/profile">Profile</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks active={contact} to="/contact">Contact</NavLinks>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    )
}

export default Navigation
