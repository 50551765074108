import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaSteam } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { FooterContainer, SocialMedia, SocialMediaWrap, SocialLogo, SocialIcon, SocialIcons, SocialIconLink } from './Footer.elements'

const Footer = () => {
    return (
        <FooterContainer>
            <SocialMedia>
            <SocialMediaWrap>
            <SocialLogo to='/'>
                <SocialIcon />
                SB
            </SocialLogo>
            <SocialIcons>
                <SocialIconLink href='https://www.facebook.com/xXx.MATRIX.xXx/' target='_blank' aria-label='Facebook'>
                <FaFacebook />
                </SocialIconLink>
                <SocialIconLink href='https://www.instagram.com/xx.matrix.xx/' target='_blank' aria-label='Instagram'>
                <FaInstagram />
                </SocialIconLink>
                <SocialIconLink href='https://www.linkedin.com/in/sujith-bellam/' target='_blank' aria-label='LinkedIn'>
                <FaLinkedin />
                </SocialIconLink>
                <SocialIconLink href='mailto:sujithbellam@gmail.com' target='_blank' aria-label='Email'>
                <FiMail />
                </SocialIconLink>
                <SocialIconLink href='https://steamcommunity.com/id/m4tr1xD/' target='_blank' aria-label='Email'>
                <FaSteam/>
                </SocialIconLink>
            </SocialIcons>
            </SocialMediaWrap>
        </SocialMedia>
    </FooterContainer>
    )
}

export default Footer
