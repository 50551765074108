import styled from 'styled-components'
import backgroundImg from '../../../media/background.svg'

export const HomeSec = styled.div`
    color: #000;
    height: 100vh;
    padding: 160px 0;
    background: url(${backgroundImg});
    background-repeat: no-repeat;
    background-size: auto;
    @media screen and (max-width: 960px) {
        height: auto;
    }
`
export const InfoRow = styled.div`
    display: flex;
    margin: 0 -15px -15px -15px;
    flex-wrap: wrap;
    align-items: center;
`
export const InfoColumn = styled.div`
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media screen and (max-width: 768px) {
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`
export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    @media screen and (max-width: 768px) {
        padding-bottom: 65px;
    }
`
export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
`
export const ImgWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`
export const Img = styled.img`
    padding-right: 0;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
`